import React, { Fragment, useState } from 'react';
import { Theme } from '@mui/material/styles';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import moment from 'moment';
import Body from '../components/content-body/Body';
import Note from '../components/note';
import SplitHeader from '../components/split-header';
import PullQuote from '../components/pull-quote';
import CTA from '../components/call-to-action';
import PGEAccordion from '../components/accordion';
import Tabset from '../components/tabset';
import Toc from '../components/toc';
import Jot from '../components/jot/';
import MessagingBlock from '../components/messaging-block';
import ContentList from '../components/content-list';
import DocumentList from '../components/document-list';
import MediaWrapper from '../components/media-wrapper';
import Related from '../components/related';
import Overlay from '../components/pge-overlay';
import SlugLink from '../components/slug-link';
import Callout from '../components/callout';
import Column from '../components/column';
import colors from '../themes/main-colors';
import PromoGroup from '../components/promo-group';
import TableWrapper from '../components/contentful-table-wrapper';
import PgeButton from '../components/pge-button/PGEButtons';
import AlertNotifications from '../components/alert-notifications';
import { LandingHeroImage } from '../components/contentful-blocks/LandingHeroImage';
import { usePathContext } from '../providers/PathProvider';
import ProgramCard, { ProgramCardProps } from '../components/program-card';
import StaticList, { StaticListProps } from '../components/static-list';
import CTACard, { CtaCardProps } from '../components/cta-card';
import CardLayout, { CardLayoutProps } from '../components/card-layout';
import GallerySlider from '../components/gallery-slider';
import WrapperImage from '../components/wrapper-image';
import SocialFeed from '../components/social-feed';
import JumpLink from '../components/jump-link';
import { Typography, useTheme } from '@mui/material';
import { truncateWithEllipses } from './format';
import PgeContact from '../components/pge-contact';
import PromoItem from '../components/promo-item';
import { isArray } from 'lodash';

export const releaseDateFormat = (releaseDate: any) => {
  const month = moment(releaseDate).format('MMMM');
  let monthFormat;

  switch (month) {
    case 'September': {
      monthFormat = 'Sept.';
      break;
    }
    default: {
      monthFormat = month?.length > 4 ? `${month?.substr(0, 3)}.` : month;
    }
  }
  return `${monthFormat} ${moment(releaseDate).format('DD, YYYY')}`;
};

interface IOverlay {
  entryName: any;
  statusAlert: any;
  content?: any;
}
export const OverlayComponent: React.FC<{
  overlay?: IOverlay;
  children?: JSX.Element;
  node?: any;
}> = (props) => {
  const { node, children, overlay } = props;
  const { content, entryName, statusAlert } = node?.data?.target || overlay;

  const [open, setOpen] = useState(false);
  const setOverlay = (status: any): any => {
    setOpen(status);
  };
  const statusComponent: any = statusAlert ? (
    <StatusAlertComponent statusAlertData={statusAlert} />
  ) : null;
  const contentBody = content ? (
    <BodyComponent bodyData={content} />
  ) : undefined;
  return (
    <>
      {/* This is an invalid anchor, needs to be a button */}
      <a style={{ cursor: 'pointer' }} onClick={(e) => setOpen(true)}>
        {children ? children : entryName}
      </a>
      <Overlay
        onOverlay={(status: any) => setOverlay(status)}
        content={contentBody ?? <></>}
        show={open}
        entryName={entryName}
        statusAlert={statusComponent}
      />
    </>
  );
};

export const RichTextOption = (theme: Theme) => ({
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node: any, children: any) => (
      <p className="pge-contentful-body">{children}</p>
    ),
    [BLOCKS.HEADING_1]: (node: any, children: any) => (
      <h1 className="pge-contenful-H1">{children}</h1>
    ),
    [BLOCKS.HEADING_2]: (node: any, children: any) => (
      <h2 className="pge-contenful-H2">{children}</h2>
    ),
    [BLOCKS.HEADING_3]: (node: any, children: any) => (
      <h3 className="pge-contenful-H3">{children}</h3>
    ),
    [BLOCKS.HEADING_4]: (node: any, children: any) => (
      <h4 className="pge-contenful-H4">{children}</h4>
    ),
    [BLOCKS.HEADING_5]: (node: any, children: any) => (
      <h5 className="pge-contenful-H5">{children}</h5>
    ),
    [BLOCKS.HEADING_6]: (node: any, children: any) => (
      <h6 className="pge-contenful-H6">{children}</h6>
    ),
    [BLOCKS.QUOTE]: (node: any, children: any) => (
      <blockquote
        style={{
          margin: '0px 0px 1.3125rem',
          borderLeft: '6px solid rgb(229, 235, 237)',
          paddingLeft: '0.875rem',
        }}
      >
        {children}
      </blockquote>
    ),
    [INLINES.HYPERLINK]: (node: any, children: any) => {
      const pattern = /\.[a-z]+$/i;

      const extension = pattern.exec(node?.data?.uri);
      let img: any = null;
      if (Array.isArray(extension)) {
        switch (extension[0]) {
          case '.pdf': {
            img = '/static/icon_PDF.svg';
            break;
          }
          default: {
            img = !node?.data?.uri?.includes('mailto:')
              ? '/static/icon_external_link.svg'
              : null;
          }
        }
      } else {
        img = !(
          node?.data?.uri?.includes('mailto:') ||
          node?.data?.uri?.includes('tel:') ||
          (node?.data?.uri?.charAt(0) === '/' &&
            node?.data?.uri?.charAt(1) !== '/')
        )
          ? '/static/icon_external_link.svg'
          : null;
      }

      return (
        <a href={node?.data?.uri} target="_blank">
          {children}
          {img && (
            <>
              {' '}
              <img css={{
                width: '14px !important',
                [theme.breakpoints.down('md')]: {
                  width: '14px !important',
                },
              }} src={img} alt="PGE" />
            </>
          )}
        </a>
      );
    },
    [INLINES.ASSET_HYPERLINK]: (node: any, children: any) => {
      return <RichTextLinkWrapper node={node} children={children} />;
    },
    [INLINES.ENTRY_HYPERLINK]: (node: any, children: any) => {
      if (node?.data?.target?.__typename) {
        const componentType = node?.data?.target?.__typename;
        const { title, slug } = node?.data?.target;
        switch (componentType) {
          case 'ContentfulWrapperExternalLink': {
            const img = !(
              slug?.includes('mailto:') ||
              slug?.includes('tel:') ||
              (slug?.charAt(0) === '/' && slug?.charAt(1) !== '/')
            )
              ? '/static/icon_external_link.svg'
              : null;
            const link = img ? (
              <a href={slug} target="_blank">
                {children}
                {img && (
                  <>
                    {' '}
                    <img
                      css={{
                        width: '14px !important',
                [theme.breakpoints.down('md')]: {
                  width: '14px !important',
                },
                      }}
                      src={img}
                      alt={title}
                    />
                  </>
                )}
              </a>
            ) : (
              <SlugLink slug={slug} linkText={children} />
            );
            return <>{link}</>;
          }
          case 'ContentfulPageOverlay': {
            return <OverlayComponent node={node} children={children} />;
          }
          default:
            return <SlugLink slug={slug} linkText={children} />;
        }
      } else {
        return <div>{children}</div>;
      }
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node: any, children: any) => {
      if (node?.data?.target?.__typename) {
        const componentType = node?.data?.target?.__typename;
        switch (componentType) {
          case 'ContentfulElementColumns3': {
            return <ColumnComponent columnData={node?.data?.target} />;
          }
          case 'ContentfulElementColumns2': {
            return <ColumnComponent columnData={node?.data?.target} />;
          }
          case 'ContentfulWrapperImage': {
            return <WrapperImage {...node?.data?.target} />;
          }
          case 'ContentfulElementCallout': {
            return <CallOutComponent calloutData={node?.data?.target} />;
          }
          case 'ContentfulUiTableExtention': {
            if (!node?.data.target) {
              return null;
            }

            return <TableWrapper {...node.data.target} />;
          }
          case 'ContentfulElementPullquote': {
            return <PullQuoteComponent pullQuoteData={node?.data?.target} />;
          }
          case 'ContentfulElementContact': {
            return <ContactComponent contactData={node?.data?.target} />;
          }
          case 'ContentfulWrapperembedMedia': {
            return <MediaWrapper {...node?.data?.target} />;
          }
          case 'ContentfulElementButtonAction': {
            const { button, buttonLink } = node?.data?.target;
            const btnProps: any = getButtonVariable(button, buttonLink);
            return <PgeButton {...btnProps} />;
          }
          case 'ContentfulElementProgramCard': {
            return <ProgramCard {...node?.data?.target} />;
          }
          case 'ContentfulModuleSocialFeed': {
            return <SocialFeed {...node?.data?.target} />;
          }
          default:
            return null;
        }
      } else {
        return <div>{children}</div>;
      }
    },
    [BLOCKS.EMBEDDED_ASSET]: (node: any, children: any) => {
      if (node?.data?.target?.__typename) {
        const { title, description, file } = node.data.target;
        const mimeType = file?.contentType || 'nope/undefined';
        const mimeGroup = mimeType.split('/')[0];

        switch (mimeGroup) {
          case 'image':
            return <img alt={description ? description : ''} src={file?.url} />;
          case 'application':
            return <a href={file?.url}>{title ? title : file?.fileName}</a>;
          default:
            return (
              <span style={{ backgroundColor: 'red', color: 'white' }}>
                {' '}
                {mimeType} embedded asset{' '}
              </span>
            );
        }
      } else {
        return <div>{children}</div>;
      }
    },
  },
});

export const getNavigationObject = () => {
  return { menu: 'test' };
};

export const getBackgroundColor = (color: any | null): any | null => {
  let bgColor = colors.white;
  switch (color) {
    case 'Blue':
      bgColor = colors.lightBlue1;
      break;
    case 'Grey':
      bgColor = colors.lightGray2;
      break;
    default:
      bgColor = colors.white;
      break;
  }
  return bgColor;
};

interface BodyComponentProps {
  bodyData?: any
}
export const BodyComponent: React.FC<BodyComponentProps> = ({
  bodyData
}) => {
  const theme = useTheme();

  // not 100% sure why content's bodyData.references could include null
  if (!bodyData || bodyData.references?.includes(null)) {
    return null
  }

  return (
    <Body bodyCopy={renderRichText(bodyData, RichTextOption(theme))} />
  );
}

interface RichTextLinkWrapperProps {
  node: any;
  children: JSX.Element;
}
const RichTextLinkWrapper: React.FC<RichTextLinkWrapperProps> = (
  props: RichTextLinkWrapperProps,
) => {
  const theme = useTheme()

  const { node, children } = props;
  const href = node?.data?.target?.file?.url;
  if (!href) {
    return null;
  }

  const contentType = node?.data?.target?.file?.contentType;
  const img: any = contentType ? getIcon(contentType) : null;

  return (
    <a href={href} target="_blank">
      {children}{' '}
      {img && <img css={{
        width: '14px !important',
                [theme.breakpoints.down('md')]: {
                  width: '14px !important',
                },
      }} src={img} alt="PGE" />}
    </a>
  );
};

interface NoteComponentProps {
  noteData: any | null;
}
export const NoteComponent: React.FC<NoteComponentProps> = ({
  noteData,
}) => {
  const noteCopy = noteData?.noteCopy ? (
    <BodyComponent bodyData={noteData?.noteCopy} />
  ) : null;

  return <Note noteHeading={noteData?.noteHeading} noteCopy={noteCopy} />;
};

export const getTabData = (tabItemArray: any) => {
  const tabsData = tabItemArray?.map((itemObj: any, index: number): any => {
    const formatedData = itemObj?.tabBody ? (
      <BodyComponent bodyData={itemObj?.tabBody} />
    ) : null;

    let tabIcon = null;
    if (itemObj.tabTitleIcon?.file?.url) {
      tabIcon = (
        <img
          src={itemObj?.tabTitleIcon?.file?.url}
          style={{ width: '28px' }}
          alt={itemObj?.tabTitle}
        />
      );
    }
    return {
      label: itemObj.tabTitle,
      data: formatedData,
      icon: tabIcon,
    };
  });
  return tabsData;
};

interface TabsetComponentProps {
  tabsetData: any | null;
}
export const TabsetComponent: React.FC<TabsetComponentProps> = ({
  tabsetData,
}) => {
  const formatedIntroCopy: any = tabsetData?.tabsetIntroCopy ? (
    <BodyComponent bodyData={tabsetData?.tabsetIntroCopy} />
  ) : null;
  const tabData = tabsetData.addItems ? getTabData(tabsetData.addItems) : null;

  return tabData ? (
    <Tabset
      header={tabsetData?.tabsetHeader}
      intro={formatedIntroCopy}
      tabsetImage={tabsetData?.tabsetImage}
      tabData={{
        tabs: tabData,
      }}
      imagePosition={tabsetData.imagePosition}
    />
  ) : null;
};

export const TocComponent: React.FC<{ tocData: any | null }> = ({
  tocData,
}) => {
  const formatedIntroCopy = tocData?.tocIntroCopy ? (
    <BodyComponent bodyData={tocData?.tocIntroCopy} />
  ) : null;
  const referenceLinks = tocData?.addTocItems ? (
    <ReferenceLinks graphqlObj={tocData?.addTocItems} />
  ) : (
    []
  );
  const chunkedArray = isArray(referenceLinks)
    ? chunk(referenceLinks, tocData?.distributeAcrossColumns)
    : [];

  return (
    <Toc
      tocImage={tocData?.tocImage}
      colorBackground={getBackgroundColor(tocData?.colorBackground)}
      distributeAcrossColumns={tocData?.distributeAcrossColumns}
      tocHeader={tocData?.tocHeader}
      tocIntroCopy={formatedIntroCopy}
      addTocItems={chunkedArray}
    />
  );
};

interface AccordionComponentProps {
  accordionData: any | null;
}

export const AccordionComponent: React.FC<AccordionComponentProps> = ({
  accordionData,
}) => {
  return (
    <PGEAccordion
      accordionItems={accordionData?.accordionItems}
      accordionHeader={accordionData?.accordionHeader}
      accordionIntroCopy={accordionData?.accordionIntroCopy}
      colorBackground={getBackgroundColor(accordionData?.colorBackground)}
    />
  );
};

export const ContactComponent: React.FC<{ contactData: any | null }> = ({
  contactData,
}) => {
  const contactCopy = contactData?.contactCopy ? (
    <BodyComponent bodyData={contactData?.contactCopy} />
  ) : null;

  const phoneNumber = (
    <a href={`tel:+1 ${contactData?.phoneNumber}`}>
      {contactData?.phoneNumber}
    </a>
  );
  const emailAddress = (
    <a href={`mailto:${contactData?.emailAddress}`}>
      {contactData?.emailAddress}
    </a>
  );

  return (
    <PgeContact
      contactCopy={contactCopy}
      contactName={contactData?.contactName}
      emailAddress={emailAddress}
      phoneNumber={phoneNumber}
    />
  );
};

interface ColumnComponentProps {
  columnData: any | null;
  pageTemplate?: Ctfl.Field['Template'];
}
export const ColumnComponent: React.FC<ColumnComponentProps> = ({
  columnData,
  pageTemplate
}) => {
  const ColumnLength = columnData?.column?.length;
  const output = [];
  const shortDesc = columnData?.columnIntroCopy ? (
    <BodyComponent bodyData={columnData?.columnIntroCopy} />
  ) : null;

  for (let i = 0; i < ColumnLength; i++) {
    const callout = (
      <GetComponent
        itemObj={columnData?.column[i]}
        index={i}
        pageTemplate={''}
        isFromColumn={true}
      />
    );
    output.push(callout);
  }
  return (
    <Column
      header={columnData?.columnHeader}
      data={output}
      backGroundColor={getBackgroundColor(columnData?.colorBackground)}
      shortDesc={shortDesc}
      leftNav={pageTemplate === 'Left Nav'}
    />
  );
};

interface HeroComponentProps {
  entries: any;
}
export const HeroComponent: React.FC<HeroComponentProps> = ({ entries }) => {
  if (!entries || !isArray(entries)) {
    return null
  }

  const [hero] = entries.filter(
    (entry: any) => entry?.__typename === 'ContentfulHeaderhero',
  );

  if (!hero) {
    return null;
  }

  const btnProps = getButtonVariable(hero.button, hero.buttonLink);

  return (
    <LandingHeroImage
      image={hero?.image?.file?.url}
      heading={hero?.heading}
      headingLineTwo={hero?.headingLineTwo}
      copy={hero?.subheading}
      button={btnProps}
      imagePosition={hero.imagePosition}
    />
  );
};

export const getButtonVariable = (btnObj: any, btnLink: any) => {
  const theme = useTheme()

  let btnJSON = null;
  if (btnObj || btnLink) {
    let btnLinkUrl = null;
    let icon = null;
    let target = null;

    switch (btnLink?.__typename) {
      case 'ContentfulWrapperExternalLink': {
        if (btnLink && btnLink?.websiteUrl) {
          btnLinkUrl = btnLink?.websiteUrl;
          target = !(
            btnLink?.websiteUrl?.includes('mailto:') ||
            btnLink?.websiteUrl?.includes('tel:') ||
            (btnLink?.websiteUrl?.charAt(0) === '/' &&
              btnLink?.websiteUrl?.charAt(1) !== '/')
          )
            ? '_blank'
            : null;
        } else {
          btnLinkUrl = btnLink?.slug;
          target = !(
            btnLink?.slug?.includes('mailto:') ||
            btnLink?.slug?.includes('tel:') ||
            (btnLink?.slug?.charAt(0) === '/' &&
              btnLink?.slug?.charAt(1) !== '/')
          )
            ? '_blank'
            : null;
        }
        break;
      }
      case 'ContentfulWrapperMediaAsset': {
        if (typeof btnLink.media !== 'undefined') {
          const { contentType, url } = btnLink?.media?.file;
          icon = contentType ? getIcon(contentType) : null;
          btnLinkUrl = url;
          target = '_blank';
        }

        break;
      }
      default: {
        const { check, resolve } = usePathContext();

        if (check(btnLink?.slug)) {
          btnLinkUrl = resolve(btnLink?.slug);
        } else {
          btnLinkUrl =
            btnLink?.slug?.charAt(0) === '/'
              ? btnLink?.slug
              : `/${btnLink?.slug}`;
        }
      }
    }

    btnJSON =
      btnLink?.__typename === 'ContentfulPageOverlay'
        ? {
            buttonMode: btnObj?.buttonType
              ? btnObj?.buttonType
              : btnObj?.buttonText
              ? 'Primary'
              : '',
            text:
              btnObj && btnObj?.buttonText ? btnObj?.buttonText : btnLink?.slug,
            icon: icon ? (
              <img src={icon} alt="PGE" css={{
                width: '14px !important',
                [theme.breakpoints.down('md')]: {
                  width: '14px !important',
                },
              }} />
            ) : null,
            buttonLink: btnLink,
          }
        : {
            buttonMode: btnObj?.buttonType
              ? btnObj?.buttonType
              : btnObj?.buttonText
              ? 'Primary'
              : '',
            text:
              btnObj && btnObj?.buttonText ? btnObj?.buttonText : btnLink?.slug,
            icon: icon ? (
              <img src={icon} alt="PGE" css={{
                width: '14px !important',
                [theme.breakpoints.down('md')]: {
                  width: '14px !important',
                },
              }} />
            ) : null,
            onClick: btnLinkUrl,
            target: target,
          };
  }
  return btnJSON;
};

export const CallOutComponent: React.FC<{ calloutData: any | null }> = ({
  calloutData,
}) => {
  const shortDesc = calloutData?.shortDescription ? (
    <BodyComponent bodyData={calloutData.shortDescription} />
  ) : null;

  const btnProps = getButtonVariable(
    calloutData?.button,
    calloutData?.buttonLink,
  );

  return calloutData ? (
    <Callout
      photo={calloutData?.image?.file?.url}
      heading={calloutData?.heading}
      shortDescription={shortDesc}
      button={btnProps}
      link={calloutData?.buttonLink}
      imagePosition={calloutData.imagePosition}
    />
  ) : null;
};

interface GetComponentProps {
  itemObj: any | null;
  index: any;
  pageTemplate?: any;
  isFromColumn: boolean;
}
export const GetComponent: React.FC<GetComponentProps> = ({
  itemObj,
  index,
  pageTemplate,
  isFromColumn = false
}) => {
  const id = itemObj?.contentful_id;
  const theme = useTheme();

  // Prepend an undescore to the id value to ensure that it doesn't start with a digit
  // https://www.w3.org/TR/CSS2/syndata.html
  // 4.1.3 Characters and case
  const anchorId = itemObj?.anchorLabel ? `_${id}` : undefined;
  switch (itemObj?.__typename) {
    case 'ContentfulElementJumpLinks': {
      return (
        <div
          key={index}
          css={{
            padding: theme.spacing(2, 0),
            [theme.breakpoints.down('md')]: {
              padding: theme.spacing(1.25, 0),
            },
          }}
          className={`spacingComponent ${itemObj.__typename}`}
        >
          <JumpLink {...itemObj} />
        </div>
      );
    }
    case 'ContentfulContentBody': {
      return (
        <div
          id={anchorId}
          key={index}
          css={{
            padding: theme.spacing(2, 0),
            [theme.breakpoints.down('md')]: {
              padding: theme.spacing(1.25, 0),
            },
          }}
          className={`spacingComponent ${itemObj.__typename}`}
        >
          <BodyComponent bodyData={itemObj?.bodyCopy} />
        </div>
      );
    }
    case 'ContentfulContentNote': {
      return (
        <div
          id={anchorId}
          css={{
            padding: theme.spacing(1, 0),
            [theme.breakpoints.down('md')]: {
              padding: theme.spacing(0.8, 0),
            },
          }}
          className={`spacingComponent ${itemObj.__typename}`}
          key={index}
        >
          <NoteComponent noteData={itemObj} />
        </div>
      );
    }
    case 'ContentfulHeaderSplit': {
      if (index) {
        return (
          <div
            key={index}
            css={{
              padding: theme.spacing(2, 0),
            [theme.breakpoints.down('md')]: {
              padding: theme.spacing(1.25, 0),
            },
            }}
            className={`spacingComponent ${itemObj.__typename}`}
          >
            <SplitHeaderComponent splitHeaderData={itemObj} />
          </div>
        );
      } else {
        return <React.Fragment key={index}></React.Fragment>;
      }
    }
    case 'ContentfulHeaderhero': {
      if (index) {
        return (
          <div
            key={index}
            css={{
              padding: theme.spacing(2, 0),
            [theme.breakpoints.down('md')]: {
              padding: theme.spacing(1.25, 0),
            },
            }}
            className={`spacingComponent ${itemObj.__typename}`}
          >
            <HeroComponent entries={[itemObj]} />
          </div>
        );
      } else {
        return <React.Fragment key={index}></React.Fragment>;
      }
    }
    case 'ContentfulElementPullquote': {
      return (
        <div
          id={anchorId}
          css={{
            padding: theme.spacing(2, 0),
            [theme.breakpoints.down('md')]: {
              padding: theme.spacing(1.25, 0),
            },
          }}
          className={`spacingComponent ${itemObj.__typename}`}
          key={index}
        >
          <PullQuoteComponent pullQuoteData={itemObj} />
        </div>
      );
    }
    case 'ContentfulElementCta': {
      if (itemObj.imageBorderColor) {
        return (
          <div id={anchorId} key={`${id}${index}`}>
            <CTACard {...(itemObj as CtaCardProps)} />
          </div>
        );
      }
      return (
        <div
          id={anchorId}
          css={{
            padding: theme.spacing(2, 0),
            [theme.breakpoints.down('md')]: {
              padding: theme.spacing(1.25, 0),
            },
          }}
          className={`spacingComponent ${itemObj.__typename}`}
          key={`${id}${index}`}
        >
          <CTAComponent CTAData={itemObj} />
        </div>
      );
    }
    case 'ContentfulWrapperForm': {
      return (
        <div
        css={{
          padding: theme.spacing(2, 0),
            [theme.breakpoints.down('md')]: {
              padding: theme.spacing(1.25, 0),
            },
        }}
          className={`spacingComponent ${itemObj.__typename}`}
          key={index}
        >
          <JotFormComponent formData={itemObj} />
        </div>
      );
    }
    case 'ContentfulElementMessagingBlock': {
      return (
        <div
          id={anchorId}
          css={{
            padding: theme.spacing(2, 0),
            [theme.breakpoints.down('md')]: {
              padding: theme.spacing(1.25, 0),
            },
          }}
          className={`spacingComponent ${itemObj.__typename}`}
          key={index}
        >
          <MessagingBlock {...itemObj} />
        </div>
      );
    }
    case 'ContentfulModuleContentList': {
      return (
        <div
          id={anchorId}
          css={{
            padding: theme.spacing(2, 0),
            [theme.breakpoints.down('md')]: {
              padding: theme.spacing(1.25, 0),
            },
          }}
          className={`spacingComponent ${itemObj.__typename}`}
          key={index}
        >
          <ContentListComponent contentListData={itemObj} />
        </div>
      );
    }
    case 'ContentfulModuleDocumentList': {
      return (
        <div
          id={anchorId}
          css={{
            padding: theme.spacing(2, 0),
            [theme.breakpoints.down('md')]: {
              padding: theme.spacing(1.25, 0),
            },
          }}
          className={`spacingComponent ${itemObj.__typename}`}
          key={index}
        >
          <DocumentListComponent documentListData={itemObj} />
        </div>
      );
    }
    case 'ContentfulWrapperImage': {
      return <WrapperImage {...itemObj} key={itemObj.contentful_id + index} />;
    }
    case 'ContentfulElementContact': {
      return (
        <div
          id={anchorId}
          css={{
            padding: theme.spacing(1, 0),
            [theme.breakpoints.down('md')]: {
              padding: theme.spacing(0.8, 0),
            },
          }}
          className={`spacingComponent ${itemObj.__typename}`}
          key={index}
        >
          <ContactComponent contactData={itemObj} />
        </div>
      );
    }
    case 'ContentfulElementCallout': {
      return (
        <div
          id={anchorId}
          css={{
            padding: theme.spacing(2, 0),
            [theme.breakpoints.down('md')]: {
              padding: theme.spacing(1.25, 0),
            },
          }}
          className={`spacingComponent ${itemObj.__typename}`}
          key={index}
        >
          <CallOutComponent calloutData={itemObj} />
        </div>
      );
    }
    case 'ContentfulElementColumns2': {
      return (
        <div
          id={anchorId}
          css={{
            padding: theme.spacing(2, 0),
            [theme.breakpoints.down('md')]: {
              padding: theme.spacing(1.25, 0),
            },
          }}
          className={`spacingComponent ${itemObj.__typename}`}
          key={index}
        >
          <ColumnComponent columnData={itemObj} pageTemplate={pageTemplate} />
        </div>
      );
    }
    case 'ContentfulElementColumns3': {
      return (
        <div
          id={anchorId}
          css={{
            padding: theme.spacing(2, 0),
            [theme.breakpoints.down('md')]: {
              padding: theme.spacing(1.25, 0),
            },
          }}
          className={`spacingComponent ${itemObj.__typename}`}
          key={index}
        >
          <ColumnComponent columnData={itemObj} />
        </div>
      );
    }
    case 'ContentfulModuleGallery': {
      const { galleryItems } = itemObj;

      return (
        <GallerySlider id={anchorId} key={`${id}${index}`} {...itemObj}>
          {galleryItems}
        </GallerySlider>
      );
    }
    case 'ContentfulModuleRelated': {
      return (
        <div
          id={anchorId}
          css={{
            padding: theme.spacing(2, 0),
            [theme.breakpoints.down('md')]: {
              padding: theme.spacing(1.25, 0),
            },
          }}
          className={`spacingComponent ${itemObj.__typename}`}
          key={index}
        >
          <RelatedComponent relatedData={itemObj} />
        </div>
      );
    }
    case 'ContentfulModuleAccordion': {
      return (
        <div
          id={anchorId}
          css={{
            padding: theme.spacing(2, 0),
            [theme.breakpoints.down('md')]: {
              padding: theme.spacing(1.25, 0),
            },
          }}
          className={`spacingComponent ${itemObj.__typename}`}
          key={index}
        >
          <AccordionComponent accordionData={itemObj} />
        </div>
      );
    }
    case 'ContentfulModuleTabset': {
      return (
        <div
          id={anchorId}
          css={{
            padding: theme.spacing(2, 0),
            [theme.breakpoints.down('md')]: {
              padding: theme.spacing(1.25, 0),
            },
          }}
          className={`spacingComponent ${itemObj.__typename}`}
          key={index}
        >
          <TabsetComponent tabsetData={itemObj} />
        </div>
      );
    }
    case 'ContentfulWrapperembedMedia': {
      return (
        <div
        css={{
          padding: theme.spacing(2, 0),
            [theme.breakpoints.down('md')]: {
              padding: theme.spacing(1.25, 0),
            },
        }}
          className={`spacingComponent ${itemObj.__typename}`}
          key={index}
        >
          <MediaWrapper {...itemObj} />
        </div>
      );
    }
    case 'ContentfulModuleToc': {
      return (
        <div
          id={anchorId}
          css={{
            padding: theme.spacing(2, 0),
            [theme.breakpoints.down('md')]: {
              padding: theme.spacing(1.25, 0),
            },
          }}
          className={`spacingComponent ${itemObj.__typename}`}
          key={index}
        >
          <TocComponent tocData={itemObj} />
        </div>
      );
    }
    case 'ContentfulModulePromoGroup': {
      const hasProgamCards = (itemObj as CardLayoutProps).addItems?.some(
        (item) => item?.__typename === 'ContentfulElementProgramCard',
      );

      if (hasProgamCards) {
        return (
          <div id={anchorId} key={`${id}${index}`}>
            <CardLayout {...itemObj} />
          </div>
        );
      }

      return (
        <div
          id={anchorId}
          css={{
            padding: theme.spacing(2, 0),
            [theme.breakpoints.down('md')]: {
              padding: theme.spacing(1.25, 0),
            },
          }}
          className={`spacingComponent ${itemObj.__typename}`}
          key={index}
        >
          <PromoGroupComponent
            promoGroupData={itemObj}
            pageTemplate={pageTemplate}
          />
        </div>
      );
    }
    case 'ContentfulContentStatusAlert': {
      return (
        <div className={`spacingComponent ${itemObj.__typename}`} key={index}>
          <StatusAlertComponent statusAlertData={[itemObj]} />
        </div>
      );
    }
    case 'ContentfulModulePromoItem': {
      return (
        <div key={index} className={`spacingComponent ${itemObj.__typename}`}>
          <PromoItemComponent promoItemData={itemObj} />
        </div>
      );
    }
    case 'ContentfulModulePromoVideoItem': {
      return (
        <div key={index} className={`spacingComponent ${itemObj.__typename}`}>
          <PromoItemComponent promoItemData={itemObj} />
        </div>
      );
    }
    case 'ContentfulElementProgramCard': {
      return (
        <ProgramCard
          id={anchorId}
          key={`${id}${index}`}
          {...(itemObj as ProgramCardProps)}
        />
      );
    }
    case 'ContentfulModuleStaticList': {
      return (
        <StaticList
          id={anchorId}
          key={`${id}${index}`}
          {...(itemObj as StaticListProps)}
        />
      );
    }
    case 'ContentfulUiTableExtention': {
      return (
        <div id={anchorId} key={id + index}>
          <TableWrapper {...itemObj} />
        </div>
      );
    }
    case 'ContentfulModuleSocialFeed': {
      return (
        <SocialFeed
          anchorId={anchorId}
          key={index}
          {...itemObj}
          isFromColumn={isFromColumn}
        />
      );
    }
    case 'ContentfulElementColumns4': {
      return (
        <div
          id={anchorId}
          css={{
            padding: theme.spacing(2, 0),
            [theme.breakpoints.down('md')]: {
              padding: theme.spacing(1.25, 0),
            },
          }}
          className={`spacingComponent ${itemObj.__typename}`}
          key={index}
        >
          <ColumnComponent columnData={itemObj} />
        </div>
      );
    }
    default: {
      return <React.Fragment key={index}></React.Fragment>; // ???
    }
  }
};

export const SplitHeaderComponent: React.FC<{
  splitHeaderData: any | null;
}> = ({ splitHeaderData }) => {
  const description = splitHeaderData?.introContent ? (
    <BodyComponent bodyData={splitHeaderData?.introContent} />
  ) : null;

  const btnProps = getButtonVariable(
    splitHeaderData?.button,
    splitHeaderData?.buttonLink,
  );

  return (
    <SplitHeader
      image={splitHeaderData?.image?.file?.url}
      video={
        splitHeaderData?.videowrappedMedia?.url ??
        splitHeaderData?.videowrappedMedia?.vimeoEmbedUrl
      }
      largeHeadline={splitHeaderData?.heading}
      paragraphHeadline={splitHeaderData?.subheading}
      description={description}
      button={btnProps}
      imagePosition={splitHeaderData.imagePosition}
    />
  );
};

export const PullQuoteComponent: React.FC<{ pullQuoteData: any }> = ({
  pullQuoteData,
}) => {
  return (
    <PullQuote
      image={pullQuoteData?.image?.file?.url}
      statement={pullQuoteData?.textStatement?.textStatement}
      attribution={pullQuoteData?.attributionLine}
      bgColor={
        pullQuoteData.colorBackground
          ? getBackgroundColor(pullQuoteData?.colorBackground)
          : colors.lightBlue1
      }
    />
  );
};

export const CTAComponent: React.FC<{ CTAData: any | null }> = ({
  CTAData,
}) => {
  const btnProps = getButtonVariable(CTAData?.button, CTAData?.buttonLink);

  return (
    <CTA
      header={CTAData?.heading}
      description={CTAData?.shortDescription}
      bgMode={CTAData?.displayOptions}
      image={CTAData?.image?.file?.url}
      button={btnProps}
    />
  );
};

//Function to create structure of content list items
export const getContentItems = (itemArray: any) => {
  const itemsList: any = [];

  itemArray?.map((item: any) => {
    const description = truncateWithEllipses(
      item?.description ?? item?.descriptionTextNode?.description,
      120,
    );

    itemsList.push([
      item?.date,
      <>
        <Typography>
          <SlugLink
            slug={item.slug}
            linkText={item.title}
            style={{ textDecoration: 'none', fontWeight: 600 }}
          />
        </Typography>
        <Typography color="primary">{description}</Typography>
      </>,
    ]);
  });
  return itemsList;
};

export const ContentListComponent: React.FC<{
  contentListData: any | null;
}> = ({ contentListData }) => {
  const Introcopy = contentListData?.contentListIntroCopy ? (
    <BodyComponent bodyData={contentListData?.contentListIntroCopy} />
  ) : null;

  const btnProps = getButtonVariable(
    contentListData?.button,
    contentListData?.buttonLink,
  );

  return (
    contentListData?.contentItems && (
      <ContentList
        heading={contentListData?.contentListHeader}
        introCopy={Introcopy ?? undefined}
        rows={getContentItems(contentListData?.contentItems)}
        button={btnProps}
        columnBackground="LightBlue"
        bordered={true}
      />
    )
  );
};

export const getIcon = (contentType: any) => {
  switch (contentType) {
    case 'application/pdf': {
      return '/static/icon_PDF.svg';
    }
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
      return '/static/icon_word.svg';
    }
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
      return '/static/icon_excel.svg';
    }
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
      return '/static/icon_powerpoint.svg';
    }
    default: {
      return null;
    }
  }
};
export const getDocumentListLinkArray = (itemArray: any) => {
  const itemsList: any = [];
  itemArray?.map((item: any) => {
    const src = item?.file?.contentType
      ? getIcon(item?.file?.contentType)
      : null;
    itemsList.push({
      title: item.title,
      url: item?.file?.url,
      src: src,
      target: src ? '_blank' : '_self',
    });
  });
  return itemsList;
};

export const DocumentListComponent: React.FC<{
  documentListData: any | null;
}> = ({ documentListData }) => {
  const introcopy = documentListData?.documentListIntroCopy ? (
    <BodyComponent bodyData={documentListData?.documentListIntroCopy} />
  ) : null;
  return (
    <DocumentList
      header={documentListData?.documentListHeader}
      intro={introcopy}
      lists={getDocumentListLinkArray(documentListData?.documents)}
    />
  );
};

export const ReferenceLinks: React.FC<{ graphqlObj: any | null }> = ({
  graphqlObj,
}) => {
  return graphqlObj?.map((itemObj: any, index: number): any => {
    const componentType = itemObj?.__typename;
    switch (componentType) {
      case 'ContentfulWrapperMediaAsset': {
        const { title, file, description } = itemObj?.media;
        const src = file?.contentType ? getIcon(file?.contentType) : null;

        return {
          title: itemObj?.entryName,
          subTitle: description,
          link: file?.url ? file?.url : '#',
          icon: {
            src: src,
            altText: description ? description : itemObj?.entryName,
          },
        };
      }
      case 'ContentfulWrapperExternalLink': {
        const img = !(
          itemObj?.slug?.includes('mailto:') ||
          itemObj?.slug?.includes('tel:') ||
          (itemObj?.slug?.charAt(0) === '/' && itemObj?.slug?.charAt(1) !== '/')
        )
          ? '/static/icon_external_link.svg'
          : null;
        const icon = {
          src: img,
          altText: itemObj?.title ? itemObj?.title : itemObj?.slug,
        };
        return {
          title: itemObj?.title ? itemObj?.title : itemObj?.slug,
          subTitle: itemObj?.subtitle,
          link: itemObj?.slug ? itemObj?.slug : '#',
          icon: icon?.src ? icon : null,
        };
      }
      case 'ContentfulPageOverlay': {
        return {
          title: (
            <OverlayComponent
              overlay={{
                content: itemObj?.content,
                entryName: itemObj?.entryName,
                statusAlert: itemObj?.statusAlert,
              }}
            />
          ),
          pageType: componentType,
        };
      }
      default: {
        return {
          title: itemObj?.title ? itemObj?.title : itemObj?.entryName,
          subTitle: itemObj?.subtitle,
          link: itemObj.slug ? itemObj.slug : '#',
        };
      }
    }
  });
};

export const chunk = (array: Array<any>, size: string) => {
  const numericSize: number = size ? parseInt(size) : 2;
  const chunked_arr = [];
  const copied = array.length ? [...array] : []; // ES6 destructuring
  const numOfChild = Math.ceil(copied.length / numericSize); // Round up to the nearest integer
  for (let i = 0; i < numericSize; i++) {
    chunked_arr.push(copied.splice(0, numOfChild));
  }
  return chunked_arr;
};
export const JotFormComponent = (formData: any | null): any | null => {
  return (
    <Jot src={formData.formUrl} isUseMinHeight={formData.isEmbeddedForm} />
  );
};
export const RelatedComponent: React.FC<{ relatedData: any | null }> = ({
  relatedData,
}) => {
  const formatedLegacy = relatedData?.legacySupplementalLinks ? (
    <BodyComponent bodyData={relatedData?.legacySupplementalLinks} />
  ) : null;

  const formatedIntroCopy = relatedData?.relatedLinksIntroCopy ? (
    <BodyComponent bodyData={relatedData?.relatedLinksIntroCopy} />
  ) : null;

  const referenceLinks = isArray(relatedData?.referenceLInks) ? (
    <ReferenceLinks graphqlObj={relatedData?.referenceLInks} />
  ) : (
    []
  );
  const chunkedArray = isArray(referenceLinks)
    ? chunk(referenceLinks, relatedData?.distributeAcrossColumns)
    : [];
  return (
    <Related
      entryName={relatedData.entryName}
      relatedLinksHeader={relatedData.relatedLinksHeader}
      relatedLinksIntroCopy={formatedIntroCopy ?? undefined}
      headerIntroAlignment={
        relatedData.headerIntroAlignment !== null
          ? relatedData.headerIntroAlignment
          : true
      }
      referenceLinks={chunkedArray}
      distributeAcrossColumns={relatedData.distributeAcrossColumns}
      legacySupplementalLinks={formatedLegacy ?? undefined}
    />
  );
};

export const StatusAlertComponent: React.FC<{
  statusAlertData: any | null;
}> = ({ statusAlertData }) => {
  return (
    <>
      {Array.isArray(statusAlertData) ? (
        statusAlertData.map((notification: any, index: number) => (
          <AlertNotifications
            key={index}
            message={
              notification?.alertCopy ? (
                <BodyComponent bodyData={notification?.alertCopy} />
              ) : null
            }
            severity={notification?.alertIconType?.toLowerCase()}
          />
        ))
      ) : statusAlertData ? (
        <AlertNotifications
          message={
            statusAlertData?.alertCopy ? (
              <BodyComponent bodyData={statusAlertData?.alertCopy} />
            ) : null
          }
          severity={statusAlertData?.alertIconType?.toLowerCase()}
        />
      ) : null}
    </>
  );
};

export const PromoGroupComponent: React.FC<{
  promoGroupData: any | null;
  pageTemplate?: Ctfl.Field['Template'];
}> = ({ promoGroupData, pageTemplate }) => {
  const promoIntroCopy = promoGroupData?.promoIntroCopy ? (
    <BodyComponent bodyData={promoGroupData?.promoIntroCopy} />
  ) : null;

  const promoItems: any = [];
  promoGroupData?.addItems?.map((item: any) => {
    promoItems.push(getPromoItemComponentPropsObject(item));
  });

  return (
    <PromoGroup
      promoItems={promoItems}
      header={promoGroupData?.promoHeader}
      desc={promoIntroCopy ?? <></>}
      bgColor={getBackgroundColor(promoGroupData?.colorBackground)}
      leftNav={pageTemplate === 'Left Nav'}
    />
  );
};

export const getPromoItemComponentPropsObject = (
  promoItemData: any | null,
): any | null => {
  let mediaType;
  let promoLink;
  let promoHeaderLink = null;

  const description = promoItemData?.description ? (
    <BodyComponent bodyData={promoItemData?.description} />
  ) : null;

  if (promoItemData?.image?.file?.url) {
    mediaType = 'image';
  } else if (
    promoItemData?.videowrappedImage?.url ??
    promoItemData?.videowrappedImage?.vimeoEmbedUrl
  ) {
    mediaType = 'video';
  } else {
    mediaType = '';
  }

  if (mediaType === 'image') {
    promoLink = promoItemData?.promoLInk;
  } else if (mediaType === 'video') {
    promoLink = promoItemData?.promoLink;
  } else {
    promoLink = promoItemData?.promoLInk ?? promoItemData?.promoLink;
  }

  switch (promoLink?.__typename) {
    case 'ContentfulWrapperMediaAsset': {
      const { file } = promoLink?.media;
      promoHeaderLink = file ? file?.url : null;
      break;
    }
    case 'ContentfulPageOverlay': {
      promoHeaderLink = promoLink;
      break;
    }
    default: {
      promoHeaderLink = promoLink ? promoLink?.slug : null;
    }
  }
  const mediaURL =
    mediaType === 'image'
      ? promoItemData?.image?.file?.url
      : mediaType === 'video'
      ? promoItemData?.videowrappedImage?.url ??
        promoItemData?.videowrappedImage?.vimeoEmbedUrl
      : '';

  const vimeoDownloadUrl =
    mediaType === 'video' && promoItemData?.videowrappedImage?.vimeoDownloadUrl;

  return {
    header: promoItemData?.heading,
    description: description,
    mediaType: mediaType,
    mediaURL: mediaURL,
    link: promoHeaderLink,
    imageLink: promoItemData?.imageLink,
    imagePosition: promoItemData?.imagePosition,
    vimeoDownloadUrl,
  };
};

export const PromoItemComponent: React.FC<{ promoItemData: any | null }> = ({
  promoItemData,
}) => {
  const promoItem = getPromoItemComponentPropsObject(promoItemData);

  return (
    <PromoItem
      header={promoItem?.heading}
      description={promoItem?.description}
      mediaType={promoItem?.mediaType}
      mediaURL={promoItem?.mediaURL}
      link={promoItem?.link}
      imageLink={promoItem?.imageLink}
      imagePosition={promoItem?.imagePosition}
      vimeoDownloadUrl={promoItem?.vimeoDownloadUrl}
    />
  );
};

interface EntriesComponentProps {
  graphqlObj: any;
  pageTemplate: any;
}
export const EntriesComponent: React.FC<EntriesComponentProps> = ({
  graphqlObj,
  pageTemplate
}) => (
  <Fragment>
    {graphqlObj.map((o: any, i: number) => (
      <GetComponent
        key={`Entry-${i}`}
        itemObj={o}
        index={i}
        isFromColumn={pageTemplate}
      />
    ))}
  </Fragment>
);

interface RenderPageComponentsProps {
  entriesData: any;
  pageTemplate?: string;
}

export const RenderPageComponents: React.FC<RenderPageComponentsProps> = ({
  entriesData,
  pageTemplate
}) => {
  if (!entriesData) {
    return null
  }
  return (
    <EntriesComponent
      graphqlObj={entriesData}
      pageTemplate={pageTemplate}
    />
  );
};
